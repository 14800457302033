@import url(https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&family=Open+Sans:wght@600;700&display=swap);
/*************
*** COLORS ***
*************/

:root {
  --black: #111111;
  --light-black: #353535;
  --lighter-black: hsl(0, 0%, 27%);
  --darker-gray: #606060;
  --dark-gray: #767676;
  --gray: #999999;
  --dark-white: #ececec;
  --white: #ffffff;
  --selection-color: #003580;
  --dark-selection-color: #ffff00;
}

/************
*** FONTS ***
************/

/************
*** RESET ***
************/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/************
*** OTHER ***
************/

body {
  font-family: "Atkinson Hyperlegible", sans-serif;
  color: #606060;
  color: var(--darker-gray);
  font-size: 16px;
  line-height: 24px;
}

body.darkMode {
  background: #111111;
  background: var(--black);
  color: #ececec;
  color: var(--dark-white);
}

body.toggled {
  transition: background-color 0.6s ease, color 0.6s ease;
}

body.toggled strong,
body.toggled h1,
body.toggled h2,
body.toggled a {
  transition: color 0.6s ease;
}

body.toggled input,
body.toggled textarea {
  transition: color 0.6s ease, background-color 0.6s ease,
    border-color 0.6s ease;
}

body.toggled footer {
  transition: background-color 0.6s ease;
}

::selection {
  background-color: #003580;
  background-color: var(--selection-color);
  color: #ffffff;
  color: var(--white);
}

strong {
  font-weight: 700;
  color: #353535;
  color: var(--light-black);
}

body.darkMode strong {
  color: #ffffff;
  color: var(--white);
}

a {
  color: #323299;
  text-decoration: underline;
  font-weight: 700;
  display: inline-block;
  padding: 0 2px;
}

a:hover {
  color: #111111;
  color: var(--black);
  transition: color 0.3s ease;
}

body.darkMode a {
  color: #ececec;
  color: var(--dark-white);
}

body.darkMode a:hover {
  color: auto;
}

.focus-ring {
  outline-offset: 1px;
  outline: 3px solid #003580;
  outline: 3px solid var(--selection-color);
}

body.darkMode .focus-ring {
  outline: 3px solid #ffff00;
  outline: 3px solid var(--dark-selection-color);
}

.DarkModeSwitch_wrapper__1iyWk {
  top: 22px;
  right: 36px;
  display: block;
  position: absolute;
  width: 75px;
  height: 40px;
  margin: 0;
  padding: 0;
  border-radius: 20px;
  cursor: pointer;
}

.DarkModeSwitch_wrapper__1iyWk.DarkModeSwitch_loaded__2gTku {
  transition: background-color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;
}

.DarkModeSwitch_wrapper__1iyWk.DarkModeSwitch_light__1J0DQ {
  background-color: #ff5349;
  border: 1.5px solid #ff5349;
}

.DarkModeSwitch_wrapper__1iyWk.DarkModeSwitch_light__1J0DQ:hover {
  background-color: #e54a41;
  border: 1.5px solid #e54a41;
}

.DarkModeSwitch_wrapper__1iyWk.DarkModeSwitch_dark__IKnK3 {
  background: black;
  border: 1.5px solid #cccccc;
  box-shadow: 0 0 20px 10px #777777;
}

.DarkModeSwitch_wrapper__1iyWk.DarkModeSwitch_dark__IKnK3:hover {
  background-color: #303030;
  border: 1.5px solid #eeeeee;
  box-shadow: 0 0 20px 10px #888888;
}

.DarkModeSwitch_circle__R8F8- {
  display: block;
  height: 32.5px;
  width: 32.5px;
  background: var(--white);
  border-radius: 50%;
}

.DarkModeSwitch_circle__R8F8-.DarkModeSwitch_loaded__2gTku {
  transition: 0.3s margin ease;
}

.DarkModeSwitch_circle__R8F8-.DarkModeSwitch_left__3sT29 {
  margin-left: 2.5px;
}

.DarkModeSwitch_circle__R8F8-.DarkModeSwitch_right__2Izje {
  margin-left: 36.5px;
}

.DarkModeSwitch_moonWrapper__2r_gH {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  width: 100%;
  height: 100%;
  transition: none;
}

.DarkModeSwitch_moon__SFyRY {
  display: block;
  color: white;
  position: absolute;
  top: 9.5px;
  left: 8.5px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: none;
}

.DarkModeSwitch_sunWrapper__2tg2d {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 21px;
  width: 100%;
  height: 100%;
  transition: none;
}

.DarkModeSwitch_sun__1m1p1 {
  display: block;
  color: white;
  position: absolute;
  top: 7.5px;
  left: 43px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: none;
}

.Heading_wrapper__PiV0g {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-size: 72px;
  /* transition: 0.6s color ease; */
  color: var(--black);
  line-height: 72px;
  margin-bottom: 4rem;
  cursor: default;
}

.Heading_wrapper__PiV0g.Heading_darkMode__A0RYZ {
  color: white;
}

.AccordionItem_wrapper__tVNRZ {
  margin-bottom: 1rem;
}

.AccordionItem_button__8GX19.AccordionItem_color0__2fTE-,
.AccordionItem_paragraph__1O6nN.AccordionItem_color0__2fTE- {
  background-color: #bdeae5;
}

.AccordionItem_button__8GX19.AccordionItem_color0__2fTE-:hover {
  background-color: #a2e1db;
}

.AccordionItem_button__8GX19.AccordionItem_color1__Rt6ur,
.AccordionItem_paragraph__1O6nN.AccordionItem_color1__Rt6ur {
  background-color: #ffd6d2;
}

.AccordionItem_button__8GX19.AccordionItem_color1__Rt6ur:hover {
  background-color: #ffc5bf;
}

.AccordionItem_button__8GX19.AccordionItem_color2__1FDjB,
.AccordionItem_paragraph__1O6nN.AccordionItem_color2__1FDjB {
  background-color: #fcfcc3;
}

.AccordionItem_button__8GX19.AccordionItem_color2__1FDjB:hover {
  background-color: #ededb2;
}

.AccordionItem_button__8GX19.AccordionItem_color3__2nHBe,
.AccordionItem_paragraph__1O6nN.AccordionItem_color3__2nHBe {
  background-color: #c4e7ed;
}

.AccordionItem_button__8GX19.AccordionItem_color3__2nHBe:hover {
  background-color: #abdee6;
}

.AccordionItem_button__8GX19.AccordionItem_color4__1sF70,
.AccordionItem_paragraph__1O6nN.AccordionItem_color4__1sF70 {
  background-color: #ffe8c8;
}
.AccordionItem_button__8GX19.AccordionItem_color4__1sF70:hover {
  background-color: #ffd8a1;
}

.AccordionItem_wrapper__tVNRZ.AccordionItem_expanded__2hVXg {
  background-color: var(--light-black);
}

.AccordionItem_button__8GX19 {
  border: none;
  width: 100%;
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-size: 24px;
  display: inline-block;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: relative;
  text-align: left;
  color: var(--black);
  z-index: 9999;
}

.AccordionItem_button__8GX19.AccordionItem_loaded__17Ko9 {
  transition: width 0.5s ease, background-color 0.6s ease, color 0.6s ease;
}

.AccordionItem_button__8GX19:hover {
  transition: background-color 0.3s ease;
}

.AccordionItem_button__8GX19.AccordionItem_expanded__2hVXg {
  width: 100%;
}

.AccordionItem_button__8GX19.AccordionItem_darkMode__24znh,
.AccordionItem_paragraph__1O6nN.AccordionItem_darkMode__24znh {
  color: var(--white);
  background-color: var(--light-black);
}

.AccordionItem_button__8GX19.AccordionItem_darkMode__24znh:hover {
  background-color: var(--lighter-black);
}

.AccordionItem_buttonInner__2OlZL {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0.75rem;
  margin: 0;
}

.AccordionItem_buttonHeading__2SxKO {
  display: inline-block;
  margin-right: 8px;
}

.AccordionItem_chevron__2KEqu {
  align-self: center;
  transition: transform 0.3s ease;
  outline: none;
  max-height: 100%;
}

.AccordionItem_chevron__2KEqu.AccordionItem_down__yao-G {
  transform: rotate(180deg);
  /* transform: scale(1, -1); */
}

.AccordionItem_paragraph__1O6nN {
  padding: 1.5rem 2rem 2rem 2rem;
  color: var(--light-black);
  transition: opacity 0.6s ease, background-color 0.6s ease, color 0.6s ease;
}

.AccordionItem_paragraph__1O6nN.AccordionItem_darkMode__24znh {
  color: var(--white);
}

.AccordionItem_paragraph__1O6nN.AccordionItem_collapsed__2IHnl {
  opacity: 0;
  /* transition: opacity 0.6s ease; */
}

@media (min-width: 540px) {
  .AccordionItem_wrapper__tVNRZ {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .AccordionItem_button__8GX19 {
    width: auto;
  }
  .AccordionItem_buttonInner__2OlZL {
    justify-content: left;
  }
  .AccordionItem_chevron__2KEqu {
    /* position: relative; */
    /* top: 4px; */
    /* left: 4px; */
  }
  .AccordionItem_chevron__2KEqu.AccordionItem_down__yao-G {
    /* top: 2px; */
  }
}

.ContactForm_wrapper__3aITH {
  width: 100%;
  max-width: 540px;
}

form.ContactForm_loading__UN-oj {
  opacity: 50%;
}

.ContactForm_label__378f8 {
  display: block;
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-size: 18px;
  line-height: 18px;
  margin-top: 1rem;
  padding: 0.3rem 0;
}

.ContactForm_nameField__pkN_M {
  width: 100%;
}

.ContactForm_emailField__3wHr6 {
  width: 100%;
}

.ContactForm_input__gm87B {
  display: block;
  width: 100%;
  border: 2px solid var(--darker-gray);
  padding: 10px 6px;
  font-size: 16px;
  font-family: "Atkinson Hyperlegible", sans-serif;
  color: var(--darker-gray);
  /* transition: background-color 0.6s ease, border-color 0.6s ease; */
}

.ContactForm_input__gm87B.ContactForm_darkMode__29yVn {
  border: 2px solid var(--gray);
  background-color: var(--light-black);
  color: var(--white);
}

.ContactForm_sendButton__1tcEk {
  background-color: #ffd6d2;
  border: none;
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight: 400;
  font-size: 24px;
  display: block;
  padding: 0.4rem 0.75rem;
  cursor: pointer;
  position: relative;
  margin: 1.5rem 0 0.75rem 0;
  /* transition: color 0.6s ease, background-color 0.6s ease; */
  color: var(--black);
}

.ContactForm_sendButton__1tcEk:hover {
  background-color: #ffc5bf;
  transition: background-color 0.3s ease;
}

.ContactForm_sendButton__1tcEk.ContactForm_darkMode__29yVn {
  color: var(--white);
  background-color: var(--light-black);
}

.ContactForm_sendButton__1tcEk.ContactForm_loaded__NpGms {
  transition: background-color 0.6s ease, color 0.6s ease;
}

.ContactForm_paperPlaneWrapper__CXcvp {
  font-size: 18px;
  display: inline-block;
  margin-left: 2px;
}

.ContactForm_paperPlane__3bbvE {
  /* display: inline-block; */
}

textarea.ContactForm_input__gm87B {
  height: 10rem;
  min-height: 10rem;
  resize: vertical;
  max-height: 25rem;
}

.ContactForm_errorMessage__1gA3R {
  color: darkred;
  position: relative;
  display: block;
}

.ContactForm_errorMessage__1gA3R.ContactForm_darkMode__29yVn {
  color: #ff90ac;
}

.ContactForm_errorIcon__2r0Y- {
  font-size: 20px;
  display: inline-block;
  position: relative;
  top: 4px;
}

.ContactForm_secondaryInfo__1qBTy {
  display: block;
  color: var(--dark-gray);
}

.ContactForm_secondaryInfo__1qBTy.ContactForm_darkMode__29yVn {
  color: var(--gray);
}

.ContactForm_hiddenIcon__2QMq8 {
  font-size: 20px;
  visibility: hidden;
  position: relative;
  top: 4px;
  width: 0px;
}

.Footer_wrapper__1UzXs {
  width: 100%;
  background-color: var(--dark-white);
  /* transition: background-color 0.6s ease; */
}

.Footer_wrapper__1UzXs.Footer_darkMode__3aj8A {
  background-color: var(--light-black);
}

.Footer_wrapper__1UzXs.Footer_darkMode__3aj8A ::selection {
  background-color: var(--dark-selection-color);
  color: var(--black);
}

.Footer_wrapperInner__3k0jc {
  width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem 3rem;
}

.Footer_header__2Lo8V {
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: var(--black);
  /* transition: color 0.6s ease; */
}

.Footer_header__2Lo8V.Footer_darkMode__3aj8A {
  color: var(--white);
}

@media (min-width: 320px) {
  .Footer_wrapperInner__3k0jc {
    width: 320px;
  }
}

@media (min-width: 420px) {
  .Footer_wrapperInner__3k0jc {
    width: 353px;
  }
}

@media (min-width: 540px) {
  .Footer_wrapperInner__3k0jc {
    width: 473px;
  }
}
@media (min-width: 768px) {
  .Footer_wrapperInner__3k0jc {
    width: 701px;
  }
}
@media (min-width: 1024px) {
  .Footer_wrapperInner__3k0jc {
    width: 957px;
  }
}

.App_main__3ZkGI {
  width: 100%;
  margin: 0 auto;
  padding: 7rem 1rem 3rem 1rem;
}

.App_main__3ZkGI.App_darkMode__2TPkn ::selection {
  background-color: var(--dark-selection-color);
  color: var(--black);
}

.App_h2__2_lG- {
  cursor: default;
  font-size: 36px;
  line-height: 36px;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--black);
}

.App_h2__2_lG-.App_darkMode__2TPkn {
  color: var(--white);
}

.App_paragraph__1-Bqp {
  margin-bottom: 1rem;
}

.App_emoji__Vcubs {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.App_emoji__Vcubs.App_darkMode__2TPkn {
  filter: grayscale(33%);
}

@media (min-width: 320px) {
  .App_main__3ZkGI {
    width: 320px;
  }
}

@media (min-width: 420px) {
  .App_main__3ZkGI {
    width: 353px;
  }
}

@media (min-width: 540px) {
  .App_main__3ZkGI {
    width: 473px;
  }
}
@media (min-width: 768px) {
  .App_main__3ZkGI {
    width: 701px;
  }
}
@media (min-width: 1024px) {
  .App_main__3ZkGI {
    width: 957px;
  }
}

.Resume_resumeOutline__3wbxs {
  outline-offset: 1px;
  outline: 3px solid var(--selection-color);
}

.Resume_wrapper__1gq7n {
  width: 100%;
  height: 100%;
  padding: 32px 0;
  font-family: "Nunito Sans", sans-serif;
  color: #767676;
}

.Resume_wrapper__1gq7n ::selection {
  background-color: var(--selection-color);
  color: var(--white);
}

.Resume_wrapper__inner__3RcLe {
  width: 950px;
  padding: 48px 92px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 5px 10px 30px 10px rgba(0, 0, 0, 0.25);
  position: relative;
}

.Resume_nameHeading__eC_h7 {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  color: #000000;
  font-size: 2rem;
  line-height: 48px;
  letter-spacing: 4px;
  /* margin-bottom: 48px; */
  margin-bottom: 12px;
  pointer-events: none;
}

.Resume_downloadLink__Gfoh0 {
  display: inline-block;
  position: absolute;
  top: 50px;
  right: 92px;
  padding: 6px 6px 0px 6px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Resume_downloadLink__Gfoh0>img {
  height: 32px;
  transition: 0.2s transform ease;
}

.Resume_downloadLink__Gfoh0:focus>img,
.Resume_downloadLink__Gfoh0:hover>img {
  transform: scale(1.15);
}

.Resume_titleHeading__2KLV6 {
  font-weight: 700;
  color: #000000;
  font-size: 1.2rem;
  margin-bottom: 4px;
  pointer-events: none;
}

.Resume_subtitleList__eWLib {
  margin-bottom: 40px;
}

.Resume_subtitleList__eWLib>li {
  display: inline-block;
}

.Resume_subtitleList__eWLib>li>a {
  color: #36845c;
  text-decoration: underline;
  font-weight: 400;
}

.Resume_subtitleList__eWLib>li>a:hover {
  /* color: #36845c; */
  text-decoration: none;
}

.Resume_subtitleList__eWLib>li::before {
  content: "|";
  padding-left: 24px;
  padding-right: 24px;
}

.Resume_subtitleList__eWLib>li:first-child::before {
  content: "";
  padding-left: 0;
  padding-right: 0;
}

.Resume_summary__14DrJ {
  line-height: 24px;
  margin-bottom: 40px;
}

.Resume_subHeading__2w_3C {
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  color: #767676;
  font-size: 1rem;
  pointer-events: none;
  letter-spacing: 3.5px;
  margin-bottom: 28px;
}

.Resume_dateRoleWrapper__2il7E {
  position: relative;
}

.Resume_dateHeading__1oHqp {
  font-weight: 400;
  font-size: 1rem;
  display: inline-block;
  position: absolute;
  pointer-events: none;
}

.Resume_roleHeading__1-Va- {
  font-weight: 700;
  color: #000000;
  font-size: 1rem;
  display: inline-block;
  padding-left: 200px;
  margin-bottom: 16px;
  pointer-events: none;
}

.Resume_expContent__2pp9o {
  margin-left: 200px;
  line-height: 24px;
  margin-bottom: 36px;
}

.Resume_expContent__2pp9o>p {
  margin-bottom: 16px;
}

.Resume_expContent__2pp9o>ul>li {
  list-style-type: disc;
  margin-left: 32px;
  margin-bottom: 7px;
}

.Resume_expContent__2pp9o>ul>li:last-child {
  margin-bottom: 0;
}

.Resume_educationSection__1iqfh {
  position: relative;
}

.Resume_educationHeading__Tbm3O {
  position: absolute;
  top: -1.5px;
  margin-bottom: 0;
}

.Resume_schoolHeading__1gDVU {
  margin-bottom: 0;
}

.Resume_educationContent__2DlH- {
  margin-left: 200px;
  margin-bottom: 36px;
}

.Resume_educationContent__2DlH->p>span {
  display: block;
}

.Resume_skillsList__2NRdg {
  text-align: center;
}

.Resume_skillsList__2NRdg>li {
  color: #000000;
  display: inline-block;
  background-color: #ddf1e7;
  padding: 4px 20px;
  margin-left: 16px;
  margin-right: 16px;
  border-radius: 6px;
  font-size: 0.9rem;
  margin-bottom: 14px;
  pointer-events: none;
}

@media (max-width: 960px) {
  .Resume_wrapper__inner__3RcLe {
    width: 748px;
    padding: 48px 64px;
  }

  .Resume_subtitleList__eWLib>li::before {
    content: "|";
    padding-left: 12px;
    padding-right: 12px;
  }

  .Resume_expContent__2pp9o {
    margin-left: 0;
  }

  .Resume_educationContent__2DlH- {
    margin-left: 200px;
  }
}

@media (max-width: 768px) {
  .Resume_wrapper__inner__3RcLe {
    width: 634px;
    padding: 48px 54px;
  }

  .Resume_nameHeading__eC_h7 {
    text-align: center;
    margin-bottom: 8px;
  }

  .Resume_downloadLinkWrapper__2BnoI {
    text-align: center;
    margin-bottom: 36px;
  }

  .Resume_downloadLink__Gfoh0 {
    position: relative;
    top: 0;
    right: 0;
    margin: 0 auto;
    display: inline-block;
  }

  .Resume_downloadLink__Gfoh0>img {
    height: 24px;
  }

  .Resume_titleHeading__2KLV6 {
    text-align: center;
  }

  .Resume_subtitleList__eWLib {
    text-align: center;
  }
}

@media (max-width: 654px) {
  .Resume_wrapper__inner__3RcLe {
    width: 554px;
    padding: 48px 46px;
  }

  .Resume_subtitleList__eWLib>li::before {
    content: "";
    padding-left: 0;
    padding-right: 0;
  }

  .Resume_subtitleList__eWLib>li {
    display: block;
  }

  .Resume_subHeading__2w_3C {
    text-align: center;
  }

  .Resume_dateHeading__1oHqp {
    position: relative;
  }

  .Resume_roleHeading__1-Va- {
    padding-left: 0;
    display: block;
  }

  .Resume_educationHeading__Tbm3O {
    position: relative;
    top: 0;
    margin-bottom: 28px;
  }

  .Resume_educationContent__2DlH- {
    margin-left: 0;
  }
}

@media (max-width: 553px) {
  .Resume_wrapper__inner__3RcLe {
    width: 100%;
    padding: 48px 42px;
  }
}
