/*************
*** COLORS ***
*************/

:root {
  --black: #111111;
  --light-black: #353535;
  --lighter-black: hsl(0, 0%, 27%);
  --darker-gray: #606060;
  --dark-gray: #767676;
  --gray: #999999;
  --dark-white: #ececec;
  --white: #ffffff;
  --selection-color: #003580;
  --dark-selection-color: #ffff00;
}

/************
*** FONTS ***
************/

@import url("https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:wght@400;700&display=swap");

/************
*** RESET ***
************/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/************
*** OTHER ***
************/

body {
  font-family: "Atkinson Hyperlegible", sans-serif;
  color: var(--darker-gray);
  font-size: 16px;
  line-height: 24px;
}

body.darkMode {
  background: var(--black);
  color: var(--dark-white);
}

body.toggled {
  transition: background-color 0.6s ease, color 0.6s ease;
}

body.toggled strong,
body.toggled h1,
body.toggled h2,
body.toggled a {
  transition: color 0.6s ease;
}

body.toggled input,
body.toggled textarea {
  transition: color 0.6s ease, background-color 0.6s ease,
    border-color 0.6s ease;
}

body.toggled footer {
  transition: background-color 0.6s ease;
}

::-moz-selection {
  background-color: var(--selection-color);
}

::selection {
  background-color: var(--selection-color);
  color: var(--white);
}

strong {
  font-weight: 700;
  color: var(--light-black);
}

body.darkMode strong {
  color: var(--white);
}

a {
  color: #323299;
  text-decoration: underline;
  font-weight: 700;
  display: inline-block;
  padding: 0 2px;
}

a:hover {
  color: var(--black);
  transition: color 0.3s ease;
}

body.darkMode a {
  color: var(--dark-white);
}

body.darkMode a:hover {
  color: auto;
}

.focus-ring {
  outline-offset: 1px;
  outline: 3px solid var(--selection-color);
}

body.darkMode .focus-ring {
  outline: 3px solid var(--dark-selection-color);
}
