.wrapper {
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-size: 72px;
  /* transition: 0.6s color ease; */
  color: var(--black);
  line-height: 72px;
  margin-bottom: 4rem;
  cursor: default;
}

.wrapper.darkMode {
  color: white;
}
