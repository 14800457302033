.wrapper {
  width: 100%;
  max-width: 540px;
}

form.loading {
  opacity: 50%;
}

.label {
  display: block;
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-size: 18px;
  line-height: 18px;
  margin-top: 1rem;
  padding: 0.3rem 0;
}

.nameField {
  width: 100%;
}

.emailField {
  width: 100%;
}

.input {
  display: block;
  width: 100%;
  border: 2px solid var(--darker-gray);
  padding: 10px 6px;
  font-size: 16px;
  font-family: "Atkinson Hyperlegible", sans-serif;
  color: var(--darker-gray);
  /* transition: background-color 0.6s ease, border-color 0.6s ease; */
}

.input.darkMode {
  border: 2px solid var(--gray);
  background-color: var(--light-black);
  color: var(--white);
}

.sendButton {
  background-color: #ffd6d2;
  border: none;
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-weight: 400;
  font-size: 24px;
  display: block;
  padding: 0.4rem 0.75rem;
  cursor: pointer;
  position: relative;
  margin: 1.5rem 0 0.75rem 0;
  /* transition: color 0.6s ease, background-color 0.6s ease; */
  color: var(--black);
}

.sendButton:hover {
  background-color: #ffc5bf;
  transition: background-color 0.3s ease;
}

.sendButton.darkMode {
  color: var(--white);
  background-color: var(--light-black);
}

.sendButton.loaded {
  transition: background-color 0.6s ease, color 0.6s ease;
}

.paperPlaneWrapper {
  font-size: 18px;
  display: inline-block;
  margin-left: 2px;
}

.paperPlane {
  /* display: inline-block; */
}

textarea.input {
  height: 10rem;
  min-height: 10rem;
  resize: vertical;
  max-height: 25rem;
}

.errorMessage {
  color: darkred;
  position: relative;
  display: block;
}

.errorMessage.darkMode {
  color: #ff90ac;
}

.errorIcon {
  font-size: 20px;
  display: inline-block;
  position: relative;
  top: 4px;
}

.secondaryInfo {
  display: block;
  color: var(--dark-gray);
}

.secondaryInfo.darkMode {
  color: var(--gray);
}

.hiddenIcon {
  font-size: 20px;
  visibility: hidden;
  position: relative;
  top: 4px;
  width: 0px;
}
