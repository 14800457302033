.wrapper {
  margin-bottom: 1rem;
}

.button.color0,
.paragraph.color0 {
  background-color: #bdeae5;
}

.button.color0:hover {
  background-color: #a2e1db;
}

.button.color1,
.paragraph.color1 {
  background-color: #ffd6d2;
}

.button.color1:hover {
  background-color: #ffc5bf;
}

.button.color2,
.paragraph.color2 {
  background-color: #fcfcc3;
}

.button.color2:hover {
  background-color: #ededb2;
}

.button.color3,
.paragraph.color3 {
  background-color: #c4e7ed;
}

.button.color3:hover {
  background-color: #abdee6;
}

.button.color4,
.paragraph.color4 {
  background-color: #ffe8c8;
}
.button.color4:hover {
  background-color: #ffd8a1;
}

.wrapper.expanded {
  background-color: var(--light-black);
}

.button {
  border: none;
  width: 100%;
  font-family: "Atkinson Hyperlegible", sans-serif;
  font-size: 24px;
  display: inline-block;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: relative;
  text-align: left;
  color: var(--black);
  z-index: 9999;
}

.button.loaded {
  transition: width 0.5s ease, background-color 0.6s ease, color 0.6s ease;
}

.button:hover {
  transition: background-color 0.3s ease;
}

.button.expanded {
  width: 100%;
}

.button.darkMode,
.paragraph.darkMode {
  color: var(--white);
  background-color: var(--light-black);
}

.button.darkMode:hover {
  background-color: var(--lighter-black);
}

.buttonInner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0.75rem;
  margin: 0;
}

.buttonHeading {
  display: inline-block;
  margin-right: 8px;
}

.chevron {
  align-self: center;
  transition: transform 0.3s ease;
  outline: none;
  max-height: 100%;
}

.chevron.down {
  transform: rotate(180deg);
  /* transform: scale(1, -1); */
}

.paragraph {
  padding: 1.5rem 2rem 2rem 2rem;
  color: var(--light-black);
  transition: opacity 0.6s ease, background-color 0.6s ease, color 0.6s ease;
}

.paragraph.darkMode {
  color: var(--white);
}

.paragraph.collapsed {
  opacity: 0;
  /* transition: opacity 0.6s ease; */
}

@media (min-width: 540px) {
  .wrapper {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .button {
    width: auto;
  }
  .buttonInner {
    justify-content: left;
  }
  .chevron {
    /* position: relative; */
    /* top: 4px; */
    /* left: 4px; */
  }
  .chevron.down {
    /* top: 2px; */
  }
}
