.wrapper {
  top: 22px;
  right: 36px;
  display: block;
  position: absolute;
  width: 75px;
  height: 40px;
  margin: 0;
  padding: 0;
  border-radius: 20px;
  cursor: pointer;
}

.wrapper.loaded {
  transition: background-color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;
}

.wrapper.light {
  background-color: #ff5349;
  border: 1.5px solid #ff5349;
}

.wrapper.light:hover {
  background-color: #e54a41;
  border: 1.5px solid #e54a41;
}

.wrapper.dark {
  background: black;
  border: 1.5px solid #cccccc;
  box-shadow: 0 0 20px 10px #777777;
}

.wrapper.dark:hover {
  background-color: #303030;
  border: 1.5px solid #eeeeee;
  box-shadow: 0 0 20px 10px #888888;
}

.circle {
  display: block;
  height: 32.5px;
  width: 32.5px;
  background: var(--white);
  border-radius: 50%;
}

.circle.loaded {
  transition: 0.3s margin ease;
}

.circle.left {
  margin-left: 2.5px;
}

.circle.right {
  margin-left: 36.5px;
}

.moonWrapper {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  width: 100%;
  height: 100%;
  transition: none;
}

.moon {
  display: block;
  color: white;
  position: absolute;
  top: 9.5px;
  left: 8.5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: none;
}

.sunWrapper {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 21px;
  width: 100%;
  height: 100%;
  transition: none;
}

.sun {
  display: block;
  color: white;
  position: absolute;
  top: 7.5px;
  left: 43px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: none;
}
