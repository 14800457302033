.wrapper {
  width: 100%;
  background-color: var(--dark-white);
  /* transition: background-color 0.6s ease; */
}

.wrapper.darkMode {
  background-color: var(--light-black);
}

.wrapper.darkMode ::selection {
  background-color: var(--dark-selection-color);
  color: var(--black);
}

.wrapperInner {
  width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem 3rem;
}

.header {
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: var(--black);
  /* transition: color 0.6s ease; */
}

.header.darkMode {
  color: var(--white);
}

@media (min-width: 320px) {
  .wrapperInner {
    width: 320px;
  }
}

@media (min-width: 420px) {
  .wrapperInner {
    width: 353px;
  }
}

@media (min-width: 540px) {
  .wrapperInner {
    width: 473px;
  }
}
@media (min-width: 768px) {
  .wrapperInner {
    width: 701px;
  }
}
@media (min-width: 1024px) {
  .wrapperInner {
    width: 957px;
  }
}
