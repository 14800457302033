.main {
  width: 100%;
  margin: 0 auto;
  padding: 7rem 1rem 3rem 1rem;
}

.main.darkMode ::selection {
  background-color: var(--dark-selection-color);
  color: var(--black);
}

.h2 {
  cursor: default;
  font-size: 36px;
  line-height: 36px;
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--black);
}

.h2.darkMode {
  color: var(--white);
}

.paragraph {
  margin-bottom: 1rem;
}

.emoji {
  user-select: none;
}

.emoji.darkMode {
  filter: grayscale(33%);
}

@media (min-width: 320px) {
  .main {
    width: 320px;
  }
}

@media (min-width: 420px) {
  .main {
    width: 353px;
  }
}

@media (min-width: 540px) {
  .main {
    width: 473px;
  }
}
@media (min-width: 768px) {
  .main {
    width: 701px;
  }
}
@media (min-width: 1024px) {
  .main {
    width: 957px;
  }
}
